// Импорт изображений с использованием require.context или динамического импорта
const importImages = (context) => context.keys().map(context);

export const oopt_2_images = importImages(require.context('./img', false, /\.(jpg)$/));
export const img_kanyon = importImages(require.context('./img_kanyon', false, /\.(jpg)$/));
export const img_savina= importImages(require.context('./img_savina', false, /\.(jpg)$/));
export const img_old_road = importImages(require.context('./img_old_road', false, /\.(jpg)$/));

export const oopt_2_routes = [
  {
    key: 1,
    title: 'Каньон',
    type: "маршрут",
    region: 'Краснодарский к.',
    distance: '3 км',
    duration: '2-2,5 ч',
    wayType: "пеший",
    images: img_kanyon,
    level: 'легкий',
    important: 'Маршрут доступен только в формате индивидуальных и групповых экскурсий по предварительной записи',
    line: 'Старт - п. Большой Утриш\nФиниш -  п. Большой Утриш',
    way: 'Туристы самостоятельно добираются до начала маршрута в п. Большой Утриш',
    safety: 'Обязателен инструктаж о правилах поведения в заповеднике',
    shedule: 'Круглогодично',
    size: '8 групп в день (по 20 человек)',
    description: 'На Черноморском побережье Кавказа между Анапой и Новороссийском расположен неповторимый, бережно сохраняемый уголок – Государственный природный заповедник «Утриш».\n\nРайон, где он расположен, уникален по своему геологическому происхождению, ландшафтным особенностям, растительному и животному миру, истории.\nВы сможете познакомиться со знаменитыми можжевелово-фисташковыми редколесьями, для сохранения которых и был изначально создан заповедник "Утриш". \nНасладиться потрясающими видами, целебным воздухом, наполненным ароматом можжевельника, увидеть величественную скалу Перерванную, к которой по легенде был прикован Прометей!\n\nМаршрут расположен в горно-лесистой местности, поэтому подходит для людей с базовой физической подготовкой, но полностью безопасен - самые крутые подъемы оборудованы удобными ступенями с перилами.\n\nПо ходу движения гид-экскурсовод делает кратковременные остановки, посвященные озвучиванию научно-познавательных фактов о биоразнообразии заповедника «Утриш», о культурных и исторических особенностях данной местности.',
    // карта
    request: 'https://utrishtourism.ru/kaniyon'
  },
  {
    key: 2,
    title: 'Савина щель',
    type: "маршрут",
    region: 'Краснодарский к.',
    distance: '3 км',
    duration: '2-2,5 ч',
    wayType: "пеший",
    images: img_savina,
    level: 'легкий',
    important: 'Маршрут закрыт до 1 сентября!\nМаршрут доступен только в формате индивидуальных и групповых экскурсий по предварительной записи.',
    line: 'Старт - с. Сукко\nФиниш -  с. Сукко',
    way: 'Туристы самостоятельно добираются до начала маршрута в с. Сукко',
    safety: 'Обязателен инструктаж о правилах поведения в заповеднике',
    shedule: 'Круглогодично',
    size: '8 групп в день (по 20 человек)',
    description: 'Маршрут начинается неподалеку от поселка Сукко и поднимается по Навагирскому хребту на высоту 270 метров.\nНа этом участке сформирована уникальная экосистема, на примере которой можно познакомиться с видовым разнообразием. На территории представлен широколиственный лес, буково-грабовая роща, хвойный лес, пресноводный ручей.\nВторое название маршрута - "Тропа черепахи", ведь именно в Савиной щели наиболее высока вероятность встретить краснокнижную черепаху Никольского. Маршрут доступен для людей всех возрастов с базовой физической подготовкой.',
    // карта
    request: 'https://utrishtourism.ru/savina'
  },
  {
    key: 3,
    title: 'Старая дорога',
    type: "маршрут",
    region: 'Краснодарский к.',
    distance: '2,35 км',
    duration: '2-2,5 ч',
    wayType: "пеший",
    images: img_old_road,
    level: 'легкий',
    important: 'Маршрут доступен только в формате индивидуальных и групповых экскурсий по предварительной записи.',
    line: 'Старт - с. Сукко\nФиниш -  п. Большой Утриш',
    way: 'Туристы самостоятельно добираются до начала маршрута в с. Сукко',
    safety: 'Обязателен инструктаж о правилах поведения в заповеднике',
    shedule: 'Круглогодично',
    size: '8 групп в день (по 20 человек)',
    description: 'Маршрут начального уровня сложности протяженностью 2350 метров с пологим подъемом до отметки в 150 метров над уровнем моря.\nС верхней точки открываются панорамные виды на озеро «Змеиное», мыс Большой Утриш и окрестности Анапы.\nБиоразнообразие представлено двумя типами лесных зон – грабово-дубовыми формациями и можжевелово-фисташковыми редколесьями. После достижения верхней точки на маршруте преобладают типичные для полуострова Абрау и заповедника «Утриш» представители субсредиземноморской флоры и фауны – можжевельники, фисташка туполистная, астрагал колючковый, черепаха Средиземноморская никольского, эмбия Средиземноморская и многие другие.\nМаршрут представляет собой знакомство с различными историческими эпохами, оставившими свой культурный след на полуострове Абрау и реликтовыми растениями – живыми ископаемыми прежних геологических эпох.',
    // карта
    request: 'https://utrishtourism.ru/old_road'
  },
]